/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { loadStripe } from "@stripe/stripe-js"
import { CartProvider } from "use-shopping-cart"
import { createGlobalStyle } from "styled-components"
import tw, { styled } from "twin.macro"

import HeaderShop from "./headerShop"

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_LIVE)

const GlobalStyle = createGlobalStyle`
  *{
    //border: 1px solid aqua;
    box-sizing:border-box;
  }
  html{
    overflow-y:scroll;
  }
  .test{
    position:absolute;
    width:100%;
    margin-left:50%;
  }
  textarea, input{
    font-family:raleway,courier;
  }
  body{
    font-family:raleway,courier;
    color:#776F6C;
    ${tw`m-0`}
    a{
      ${tw`no-underline`}
      color:#776F6C;
      font-size:18px;
    }
    h1{
      ${tw`text-2xl sm:text-4xl`}
    }
    h2{
      ${tw`text-lg font-bold`}
    }
    h3{
      ${tw`text-2xl`}
      font-weight:300;
    }
    .mainWrap{
      ${tw`w-full md:w-3/4 lg:w-3/4 mr-auto ml-auto`}
    }
  }
`

const LayoutShop = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleShopQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  let stripeUrl
  if (location.origin === "http://localhost:8000") {
    stripeUrl = `http://localhost:8000`
  } else if (location.origin === "http://dev.studioconscious.com") {
    stripeUrl = `http://dev.studioconscious.com`
  } else if (location.origin === "http://studioconscious.com") {
    stripeUrl = "http://studioconscious.com"
  }

  return (
    <CartProvider mode="checkout-session" stripe={stripePromise} currency="CAD">
      <HeaderShop siteTitle={data.site.siteMetadata.title} />
      <GlobalStyle />

      <main className="mainWrap">{children}</main>
    </CartProvider>
  )
}

LayoutShop.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutShop
