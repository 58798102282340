import React, { useRef, useState, useEffect } from "react"

import { useShoppingCart, formatCurrencyString } from "use-shopping-cart"
import tw, { styled } from "twin.macro"
import * as queryString from "query-string"

const Button = styled.button`
  border-radius: 20px;
  text-transform: lowercase;
  padding: 10px;
  display: inline-block;
  margin-left: 20%;
  transform: translateY(-5px);
  background: #78807a;
  border: none;
  color: #fff;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
`

const Cart = styled.div`
  position: relative;
  display: inline-block;
  width: 150px;
  .already {
    position: absolute;
    top: 20px;
    right: 0;
    font-size: 12px;
    font-weight: 700;
  }
`

const AddToCart = ({ sku, cartAdd, location }) => {
  const parsed = queryString.parse(location.pathname)

  console.log("location")
  console.log(location.pathname)

  var id = location.pathname

  var final = id.substr(id.lastIndexOf("/") + 1)

  console.log(final)

  const { cartDetails } = useShoppingCart()

  // let btnRef = useRef()

  // const [count, setCount] = useState(0)

  // var countLocal = localStorage.setItem("count", count)

  //var county = localStorage.getItem("county")

  // console.log(cartDetails)

  //console.log(cartDetails[final].quantity)

  // useEffect(() => {
  //   var countLocalGet = localStorage.getItem("count")

  //   setCount(countLocalGet)
  // }, [])

  //useEffect(() => {
  //localStorage.setItem("count", count)
  //})

  var cartQ = cartDetails[final] && cartDetails[final].quantity

  console.log(cartDetails[final])
  const [alreadyInCart, setAlreadyInCart] = useState(false)

  const { addItem } = useShoppingCart()
  const handleButtonClick = () => {
    //setCount(count + 1)

    //console.log(count)

    if (cartDetails[final]) {
      setAlreadyInCart(true)
    }

    if (cartQ === 0 || cartDetails[final] === undefined) {
      // if (cartDetails[final] === undefined && cartQ === 1) {
      //   return
      // } else {
      //localStorage.setItem("county", false)
      addItem(sku)
      cartAdd()
      // }
    }
    // if (btnRef.current) {
    //   btnRef.current.setAttribute("disabled", "disabled")
    // }
  }
  return (
    <Cart>
      {alreadyInCart && <p className="already">This is already in your cart</p>}
      <Button onClick={handleButtonClick}>Add To Cart</Button>
    </Cart>
  )
}

export default AddToCart
