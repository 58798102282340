import React, { useState, useEffect } from "react"
import LayoutShop from "../components/layoutShop"
import Img from "gatsby-image"
import { graphql, Link } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import tw, { styled } from "twin.macro"
import { CartItems } from "../components/cart"
import AddToCart from "../components/addToCart"
import { useForm } from "react-hook-form"
import HCaptcha from "@hcaptcha/react-hcaptcha"
import { Loading } from "../components/loading"
import { FiShoppingCart } from "react-icons/fi"
import ImageGallery from "react-image-gallery"
import { FiChevronLeft, FiChevronRight } from "react-icons/fi"
import * as queryString from "query-string"
import Footer from "../components/Footer"
import { useShoppingCart } from 'use-shopping-cart'
import LogRocket from 'logrocket';
LogRocket.init('ziiqiw/studio-conscious');


const Item = styled.div`
  //${tw`grid lg:col-gap-16 lg:grid-cols-2 mt-32`}
  display:grid;
  grid-template-columns:55% 35%;
  grid-gap:50px;
  @media(min-width:600px){

    margin-top:100px;
  }
  p{
    line-height:2;
  }
  @media(max-width:1280px){
    display:block;
  }
  iframe {
    ${tw`ml-auto mr-auto md:ml-0 block mt-5 mb-5`}
  }
  h1,
  h3,
  p {
    padding:0 25px;
    ${tw`md:p-5 lg:p-0`}
  }
  h1{
    display:inline-block;
    padding-right:25px;
  }
  input,
  textarea {
    ${tw`mt-3 mb-3 p-2 w-10/12 block ml-auto mr-auto md:w-full`}
    border:none;
    border-bottom: 1px solid #000;
    &:focus {
      outline: none;
    }
  }
  textarea {
    resize: none;
    line-height: 22px;
    height: 100px;
  }
  input[type="submit"] {
    //border: 1px solid #000;
    border-radius: 20px;
    background: #565c4a;
    color: #fff;
    width: 300px;
    &:hover{
      cursor:pointer;
    }
    ${tw`ml-auto mr-auto md:ml-0 block mt-5 mb-5`}
    &.disabled {
      background: #78807a;
      color:#fff;
      &:hover {
        cursor: not-allowed;
      }
    }
  }
`
const ItemAsk = styled.div`
  @media (min-width: 600px) {
    margin-top: 100px;
  }

  p {
    line-height: 2;
  }
  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 50px;
    margin-bottom: 100px;
    @media (max-width: 1280px) {
      display: block;
    }
  }
  @media (max-width: 1280px) {
    display: block;
  }
  iframe {
    ${tw`ml-auto mr-auto md:ml-0 block mt-5 mb-5`}
  }
  h1,
  h3,
  p {
    ${tw`p-5 lg:p-0`}
  }
  h1 {
    display: inline-block;
    padding-right: 25px;
  }
  input,
  textarea {
    ${tw`mt-3 mb-3 p-2 w-10/12 block ml-auto mr-auto md:w-full`}
    border:none;
    border-bottom: 1px solid #000;
    &:focus {
      outline: none;
    }
  }
  textarea {
    resize: none;
    line-height: 22px;
    height: 100px;
  }
  input[type="submit"] {
    //border: 1px solid #000;
    border-radius: 20px;
    background: #565c4a;
    color: #fff;
    width: 300px;
    &:hover {
      cursor: pointer;
    }
    ${tw`ml-auto mr-auto md:ml-0 block mt-5 mb-5`}
    &.disabled {
      background: #78807a;
      color: #fff;
      &:hover {
        cursor: not-allowed;
      }
    }
  }
`

const ItemDesc = styled.div`
  ${tw`text-center`}
  padding:25px 0;
  background: ${({ background }) => background};
  @media (min-width: 600px) {
    margin-top: 100px;
    margin-bottom: 75px;
  }
  font-family: "Playfair Display";
  h2 {
    ${tw`text-2xl md:text-4xl lg:text-5xl`}
  }
  color: #fff;
  span {
    display: block;
    @media (min-width: 1280px) {
      display: inline-block;
      padding: initial;
      width: 100%;
    }
    text-align: left;
    p.title {
      ${tw`text-lg font-bold`}
      margin-right:auto;
    }
  }
  .border {
    text-align: center;
    width: 100%;
    height: 1px;
    border-left: none;
    border-top: 1px solid #fff;
    align-self: center;
    @media (min-width: 1280px) {
      border-left: 1px solid #fff;
      width: 1px;
      justify-self: center;
      height: 100%;
    }
  }
`

const Price = styled.div`
  ${tw`grid grid-cols-3 p-5 lg:p-0 lg:w-1/2 items-center`}
`

const CartButton = styled.div`
  position: fixed;
  top: 15px;
  right: 20px;
  @media (min-width: 600px) {
    right: 30px;
    top: 29px;
  }
  z-index: 6000;
  button {
    &:hover {
      cursor: pointer;
    }
    background: none;
    border: none;
    &:focus {
      outline: none;
    }
    div{
      display:grid;
      align-items:center;
      justify-items:center;
      background:lightgray;
      border-radius:100px;
      width:22px;
      height:22px;
      display:block;
      position:absolute;
      top:-10px;
      left:15px;
      span{
        line-height:1.6;
      }
    }
  }
`

const Full = styled.div`
  ${tw`w-3/4 mr-auto ml-auto`}
`
const LeftRight = styled.div`
  ${tw`w-3/4 mr-auto ml-auto grid lg:grid-cols-2`}
  grid-column-gap:50px;
  line-height: 2;
`
const RightLeft = styled.div`
  ${tw`w-3/4 mr-auto ml-auto grid lg:grid-cols-2`}
  grid-column-gap:50px;
  line-height: 2;
`

const Quote = styled.div`
  ${tw`text-center`}
  padding: 50px 0;
  q {
    quotes: "“" "”" "‘" "’";
    font-size: 36px;
    font-weight: 700;
    &::before {
      content: open-quote;
    }
    &::after {
      content: close-quote;
    }
  }
`

const Ask = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  margin: 0 auto;
  @media (min-width: 600px) {
    width: 50%;
  }
  justify-self: center;
  h1 {
    text-align: center;
    padding: 0;
    margin: 0 auto;
    display: block;
  }
`

const GalleryWrapper = styled.div`
  position: relative;
  button {
    z-index: 1000;
    border: none;
    background: none;
    &.image-custom-left {
      position: absolute;
      top: 50%;
      //transform: translateY(300px);
    }
    &.image-custom-right {
      position: absolute;
      top: 50%;
      right: 0;
      //transform: translateY(300px) translateX(675px);
    }
    &:focus {
      outline: none;
    }
    &:hover {
      cursor: pointer;
    }
  }
`

const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
  },
}

const StoreDetails = ({ data, location }) => {
  const parsed = queryString.parse(location)

  var id = location.pathname

  var final = id.substr(id.lastIndexOf("/") + 1)

  console.log(final)

  let fetchUrl
  if (location.origin === "http://localhost:8888") {
    fetchUrl = `http://localhost:8888/.netlify/functions/sold-status`
    //fetchUrl = `https://studioconscious.com/.netlify/functions/sold-status`
    //fetchUrl = `https://i30i44zzs6.execute-api.us-east-1.amazonaws.com/Prod/sold`
  } else if (location.origin === "https://dev.studioconscious.com") {
    fetchUrl = `https://i30i44zzs6.execute-api.us-east-1.amazonaws.com/Prod/sold`
    //fetchUrl = `https://studioconscious.com/.netlify/functions/sold-status`
  } else {
    fetchUrl = "/.netlify/functions/sold-status"
  }

  const [sold, setSold] = useState(false)

  var fetchData = async function () {
    const response = await fetch(fetchUrl, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        price_id: final,
      }),
    })
      .then(res => {
        return res.json()
      })
      .catch(error => console.log(error))

    return response
  }

  // useEffect(() => {
  //   fetchData().then(response => {
  //     console.log(response)
  //     if (response.sold !== []) {
  //       setSold(response.sold[0])
  //     }
  //   })
  // }, [])

  // console.log("setsold")
  // console.log(sold)
  // console.log("setsold")

  var images = []

  data.allSanityProducts.edges.map(({ node: product }, i) => {
    product.galleryImages.map(gallery => {
      images.push({
        original: `${gallery?.image?.asset?.url}`,
        thumbnail: `${gallery?.image?.asset?.url}`,
      })
    })
  })

  data.allSanityProducts.edges.map(({ node: product }) => {
    images.unshift({
      original: `${product?.featureImage?.asset?.url}`,
      thumbnail: `${product?.featureImage?.asset?.url}`,
    })
  })

  console.log(images)

  const [close, setClose] = useState(false)

  const toggleCart = () => {
    setClose(!close)
  }

  const showCart = () => {
    setClose(true)
  }

  const format = (amount, currency) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
    }).format((amount / 100).toFixed(2))

  function renderLeftNav(onClick, disabled) {
    return (
      <button
        className="image-custom-left"
        disabled={disabled}
        onClick={onClick}
      >
        <FiChevronLeft size={42} />
      </button>
    )
  }
  function renderRightNav(onClick, disabled) {
    return (
      <button
        className="image-custom-right"
        disabled={disabled}
        onClick={onClick}
      >
        <FiChevronRight size={42} />
      </button>
    )
  }

  const { cartCount } = useShoppingCart()


  

  return (
    <>
      {data.allSanityProducts.edges.map(({ node: product }, i) => {
        return (
          <React.Fragment key={i}>
            <Item>
              {/* <Img
                fluid={product.featureImage && product.featureImage.asset.fluid}
              /> */}
              <GalleryWrapper>
                <ImageGallery
                  showPlayButton={false}
                  showNav={true}
                  items={images}
                  thumbnailPosition="bottom"
                  renderLeftNav={renderLeftNav}
                  renderRightNav={renderRightNav}
                  slideDuration={1000}
                />
              </GalleryWrapper>
              <div>
                <h1>{product.name}</h1>
                {sold ? (
                  <h1>Sold Out</h1>
                ) : (
                  <>
                  {/* 
                  <AddToCart
                    location={location}
                    sku={{
                      price_id: product.price_id,
                      name: product.name,
                      price: product.price,
                      currency: "CAD",
                      product_data: location,
                    }}
                    cartAdd={showCart}
                  />
                  */}

                  </>
                )}
                <Price>
                  <h2>Price:</h2>
                  {format(product.price, "CAD")}
                </Price>
                <BlockContent
                  blocks={product._rawText}
                  serializers={serializers}
                />
              </div>
            </Item>
          </React.Fragment>
        )
      })}
      {/* <CartItems close={close} location={location} />
      <CartButton>
        <button onClick={toggleCart}>
          <FiShoppingCart size={25} color="#363636" />
          {cartCount > 0 && <div><span>{cartCount}</span></div>}

        </button>
      </CartButton> */}
    </>
  )
}

const AskWrap = ({ location }) => {
  const { register, handleSubmit, watch, errors } = useForm()

  let fetchUrl
  console.log(location)
  if (location.origin === "http://gatsby.local:8000") {
    fetchUrl = `http://localhost:8888/.netlify/functions/product-question`
  } else if (location.origin === "http://dev.studioconscious.com") {
    fetchUrl = `https://studioconscious-dev.netlify.app/.netlify/functions/product-question`
  } else {
    fetchUrl = "/.netlify/functions/product-question"
  }

  const [captchaValue, setCaptchaValue] = useState(null)
  const [submitButton, setSubmitButton] = useState(true)
  const [captchaFailed, setCaptchaFailed] = useState(false)
  const [nextStage, setNextStage] = useState(false)
  const [failedEmail, setFailedEmail] = useState(false)
  const [loading, setLoading] = useState(false)

  function onVerifyCaptcha(token) {
    console.log(token)
    setCaptchaValue(token)
    setSubmitButton(false)
  }

  const onSubmit = async data => {
    setLoading(true)
    console.log(data)
    console.log(fetchUrl)
    const response = await fetch(fetchUrl, {
      method: "POST",
      body: JSON.stringify({
        data,
        captcha: captchaValue,
      }),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        if (data.captcha === false) {
          setCaptchaFailed(true)
        }
        if (data.email === true) {
          setNextStage(true)
        } else {
          setNextStage(true)
          setFailedEmail(true)
        }
      })
      .catch(error => {
        console.error("Error:", error)
        setNextStage(true)
        setFailedEmail(true)
      })
  }

  function retry() {
    setLoading(false)
  }

  return (
    <ItemAsk>
      <Ask>
        <Loading>
          {!loading ? (
            <>
              <h3>Looking for a custom set?</h3>
              <form onSubmit={handleSubmit(onSubmit)}>
                {/* include validation with required or other standard HTML validation rules */}
                <div>
                  <input
                    name="name"
                    placeholder="Full Name"
                    type="text"
                    ref={register({ required: true })}
                  />
                  {/* errors will return when field validation fails  */}
                  {errors.name && <span>This field is required</span>}
                  <input
                    name="email"
                    placeholder="Email Address"
                    type="email"
                    ref={register({ required: true })}
                  />
                  {/* errors will return when field validation fails  */}
                  {errors.email && <span>This field is required</span>}
                  <textarea
                    name="question"
                    placeholder="Let us know what your looking for?"
                    ref={register({ required: true })}
                  />
                </div>
                <div>
                  {/* include validation with required or other standard HTML validation rules */}
                  {/* <HCaptcha
                    className="captcha"
                    sitekey="acaf5962-6fc2-4e49-86db-3b13f0a60f24"
                    onVerify={onVerifyCaptcha}
                  /> */}
                  <input
                    type="submit"
                    // className={submitButton ? "disabled" : ""}
                    // disabled={submitButton ? "disabled" : ""}
                  />
                </div>
              </form>
            </>
          ) : (
            <div>
              {captchaFailed ? (
                <>
                  <p>Captcha Verification Failed</p>
                  <button onClick={retry}>retry</button>
                </>
              ) : (
                <>
                  {!nextStage ? (
                    <div className="loader">Loading...</div>
                  ) : (
                    <>
                      {failedEmail ? (
                        <>
                          <h1>Email Failed Contact us here </h1>
                          <h2>
                            <a href="mailto:hello@studioconscious.com">
                              hello@studioconscious.com
                            </a>
                          </h2>
                          <h3>
                            Or Retry here <button onClick={retry}>retry</button>
                          </h3>
                        </>
                      ) : (
                        <>
                          <h1>Succesfully submitted</h1>
                          <svg
                            className="checkmark"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 52 52"
                          >
                            <circle
                              className="checkmark__circle"
                              cx="26"
                              cy="26"
                              r="25"
                              fill="none"
                            />
                            <path
                              className="checkmark__check"
                              fill="none"
                              d="M14.1 27.2l7.1 7.2 16.7-16.8"
                            />
                          </svg>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </Loading>
      </Ask>
    </ItemAsk>
  )
}

const WithLayout = Component => {
  return props => (
    <>
      <LayoutShop location={props.location}>
        <Component {...props} />
      </LayoutShop>
      {props.data.allSanityProducts.edges.map(({ node: product }, i) => {
        return (
          <React.Fragment key={i}>
            <ItemDesc background={product.bannerBg && product.bannerBg.hex}>
              <div>
                <h2>{product.bannerTitle}</h2>
              </div>
            </ItemDesc>
            {product.descBlock.map(block => {
              return (
                <>
                  <div>
                    {block._type === "full" && (
                      <Full>
                        <h1>{block.header}</h1>
                        <Img fluid={block.image && block.image.asset.fluid} />
                        <BlockContent
                          blocks={block._rawText}
                          serializers={serializers}
                        />
                      </Full>
                    )}
                    {block._type === "left-right" && (
                      <LeftRight>
                        <Img fluid={block.image && block.image.asset.fluid} />
                        <div>
                          <h1>{block.header}</h1>
                          <BlockContent
                            blocks={block._rawText}
                            serializers={serializers}
                          />
                        </div>
                      </LeftRight>
                    )}
                    {block._type === "right-left" && (
                      <RightLeft>
                        <div>
                          <h1>{block.header}</h1>
                          <BlockContent
                            blocks={block._rawText}
                            serializers={serializers}
                          />
                        </div>
                        <Img fluid={block.image && block.image.asset.fluid} />
                      </RightLeft>
                    )}
                    {block._type === "quote" && (
                      <Quote>
                        <q>{block.quote}</q>
                      </Quote>
                    )}
                  </div>
                </>
              )
            })}
          </React.Fragment>
        )
      })}
      <AskWrap location={props.location} />
      <Footer />
    </>
  )
}

export default WithLayout(StoreDetails)

export const query = graphql`
  query StoreDeatailsQuery($slug: String!) {
    allSanityProducts(filter: { price_id: { eq: $slug } }) {
      edges {
        node {
          galleryImages {
            image {
              asset {
                url
                fixed(width: 700) {
                  ...GatsbySanityImageFixed
                }
              }
            }
          }
          sold
          price_id
          product_id
          price
          name
          bannerTitle
          bannerBg {
            hex
          }
          _rawText(resolveReferences: { maxDepth: 10 })
          featureImage {
            asset {
              url
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          }
          descBlock {
            ... on SanityFull {
              _key
              _type
              header
              _rawText
              image {
                asset {
                  fluid(maxWidth: 700) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
            ... on SanityLeftRight {
              _key
              _type
              _rawText
              image {
                asset {
                  fluid(maxWidth: 700) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              header
            }
            ... on SanityRightLeft {
              _key
              _type
              _rawText
              image {
                asset {
                  fluid(maxWidth: 700) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              header
            }
            ... on SanityQuote {
              _key
              _type
              quote
            }
          }
        }
      }
    }
  }
`
