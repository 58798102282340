import tw, { styled } from "twin.macro"

export const Loading = styled.div`
  // position: relative;
  // background: #dbdbd9;
  // padding-top: 100px;
  // margin-top: 200px;
  // @media (min-width: 768px) {
  //   padding-top: 150px;
  //   margin-top: 250px;
  // }
  // @media (min-width: 1280px) {
  //   padding-top: 250px;
  //   padding-bottom: 250px;
  //   margin-top: 300px;
  // }
  // @media (min-width: 1600px) {
  //   padding-top: 350px;
  // }
  .loader,
  .loader:after {
    border-radius: 50%;
    width: 7em;
    height: 7em;
  }
  .loader {
    margin: 60px auto;
    @media (min-width: 1280px) {
      margin-left: 0;
    }
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(116, 116, 116, 0.7);
    border-right: 1.1em solid rgba(163, 163, 163, 0.7);
    border-bottom: 1.1em solid rgba(110, 110, 110, 0.7);
    border-left: 1.1em solid #000;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #5f5744;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }

  .checkmark {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 20px auto;
    @media (min-width: 1280px) {
      //margin-left: 0;
    }
    box-shadow: inset 0px 0px 0px #5f5744;
    animation: fill 0.4s ease-in-out 0.4s forwards,
      scale 0.3s ease-in-out 0.9s both;
  }

  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }

  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes scale {
    0%,
    100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px #5f5744;
    }
  }
`
